// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-j-3-scrum-tsx": () => import("./../src/pages/j3scrum.tsx" /* webpackChunkName: "component---src-pages-j-3-scrum-tsx" */),
  "component---src-pages-planus-tsx": () => import("./../src/pages/planus.tsx" /* webpackChunkName: "component---src-pages-planus-tsx" */),
  "component---src-pages-politikos-tsx": () => import("./../src/pages/politikos.tsx" /* webpackChunkName: "component---src-pages-politikos-tsx" */),
  "component---src-pages-religare-tsx": () => import("./../src/pages/religare.tsx" /* webpackChunkName: "component---src-pages-religare-tsx" */)
}

